import styled from "styled-components";

namespace sc {
  export const Container = styled.div<{
    isOverlayActive: boolean;
    isCardJoiningOpen: boolean;
    isMenuAppOpen: boolean;
  }>`
    position: ${(props) =>
      props.isCardJoiningOpen || props.isMenuAppOpen
        ? "relative"
        : props.isOverlayActive
        ? "fixed"
        : "relative"};
    top: 0;
    left: 0;
    z-index: ${(props) => (props.isOverlayActive ? -1 : 1)};
    display: flex;
    justify-content: center;
    padding: 0px;
    width: 100%;
  `;

  export const GridWrapper = styled.div`
    width: 100vw;
  `;
}
export default sc;
