/* eslint-disable class-methods-use-this */
/* eslint-disable unicorn/consistent-function-scoping */
import { action, computed, flow, makeObservable, override } from "mobx";

import { DynamicLazyModalLoader } from "../components/DynamicModalLoader/DynamicLazyModalLoader";
import { IModalDeleteCardStore } from "../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../components/TheMessage/TheMessageStore";
import { DeckDetailApi } from "../shared/apis/DeckDetailApi";
import { showMessageEmailNotVerified } from "../shared/helpers/showMessageEmailNotVerified";
import { translateAPIError } from "../shared/helpers/translateApiError";
import {
  CardArticleLayoutType,
  CardRegular,
  CardSize,
  CardType,
  MasterLinkType,
} from "../shared/models/Card";
import { Deck } from "../shared/models/Deck";
import { CardBaseModel } from "./CardBaseModel";
import { IDeviceStore } from "./DeviceStore";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { HomeStore } from "./HomeStore";
import { IModalStore } from "./ModalStore";
export class CardHomeModel extends CardBaseModel {
  constructor(
    card: CardRegular,
    private homeStore: HomeStore,
    protected modalStore: IModalStore,
    private modalDeleteCardStore: IModalDeleteCardStore,
    private theMessageStore: ITheMessageStore,
    protected userSessionStore: IUserSessionStore,
    protected featureFlagging: FeatureFlaggingData,
    private deckDetailApi: DeckDetailApi,
    protected deviceStore: IDeviceStore,
  ) {
    super(
      card,
      userSessionStore,
      featureFlagging,
      deviceStore,
      null,
      modalStore,
    );
    makeObservable(this);
  }

  @computed get comment(): string {
    return this.cardContent?.comment || "";
  }

  @computed get curatorName(): string {
    return this.cardContent.curator?.name || "";
  }

  @computed get vanityName(): string {
    return this.cardContent.curator?.vanityName || "";
  }

  @computed get pathSharing(): string {
    return (
      `${this.cardContent.deck?.path?.join("/")}/${this.cardContent.id}` || ""
    );
  }

  @computed get deck(): Deck | null {
    return this.cardContent?.deck || null;
  }

  @computed get id(): string {
    return this.cardContent?.id || "";
  }

  @override get isCardOwner(): boolean {
    // anonymous
    if (!this.userSessionStore.user) {
      return false;
    }

    // show save button for editor
    if (this.userSessionStore.user?.vanityName === "earthtoday") {
      return false;
    }

    return (
      this.card.curator.vanityName === this.userSessionStore.user.vanityName
    );
  }

  get isCardSizeOptionVisible(): boolean {
    return false;
  }

  @computed get isCardSingleOptionActive(): boolean {
    return !this.card.featured && this.card.size === CardSize.SINGLE;
  }

  @computed get isCardDoubleOptionActive(): boolean {
    return !this.card.featured && this.card.size === CardSize.DOUBLE;
  }

  @computed get isCardHeaderOptionActive(): boolean {
    return this.card.featured || false;
  }

  @computed get isFeaturedDeck(): boolean {
    return this.card.featured || false;
  }

  @computed get deckPathString(): string {
    return this.cardContent.deck?.path?.join("/") || "";
  }

  @computed get deckPath(): string[] {
    return this.cardContent?.deck?.path || [];
  }

  @computed get deckName(): string {
    return this.cardContent.deck?.name || "";
  }

  @computed get newestId(): string {
    return this.card.id || "";
  }

  get isCardDrafting(): boolean {
    return false;
  }

  @action.bound
  onStarCard(): void {
    if (!this.isUserLoggedin) {
      if (this.featureFlagging.flags.enableLoginWithQRCode) {
        this.homeStore.onOpenLoginModal();
      } else {
        this.modalStore.openModal("loginForm");
      }
      return;
    }

    if (!this.userSessionStore.user?.emailAddressVerified) {
      showMessageEmailNotVerified(this.theMessageStore, this.userSessionStore);
      return;
    }
    this.homeStore.updateStarCard(this.card.id, !this.metadata.starred);
  }

  @action.bound cardRepostIdUpdate(): void {
    if (!this.isUserLoggedin) {
      if (this.featureFlagging.flags.enableLoginWithQRCode) {
        this.homeStore.onOpenLoginModal();
      } else {
        this.modalStore.openModal("loginForm");
      }
      return;
    }

    if (!this.userSessionStore.user?.emailAddressVerified) {
      showMessageEmailNotVerified(this.theMessageStore, this.userSessionStore);
      return;
    }

    if (!this.userSessionStore.isCurator) {
      return;
    }

    this.modalStore.openLazyModal({
      name: "cardCreate",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../components/ModalCreateCard/ModalCreateCard")
          }
          driver={{
            cardRepostId: this.cardContent.id,
            cardLayout: this.layoutType || CardArticleLayoutType.EDITORIAL,
          }}
        />
      ),
    });
  }

  @action.bound updateStarCard(starred: boolean): void {
    this.metadata.starred = starred;
  }

  @action.bound updateCardDelete(): void {
    this.modalDeleteCardStore.updateCardDelete(this);
  }

  @action.bound onDeleteCard(): void {
    this.homeStore.onDeleteCard(this.card.id);
  }

  @action.bound onOpenModalCardEdit(): void {
    if (!this.cardContent) return;
    const principles = this.card.categoryPrinciples || [];

    this.modalStore.openModal({
      name: "cardCreate",
      driver: {
        cardWhy: this.card.comment || "",
        categoryName: principles.length > 0 ? principles[0] : "",
        deckId: this.card.deck?.id || null,
        url: this.cardContent.content.url,
        cardId: this.card.id || "",
        actionType:
          this.card.content.type === MasterLinkType.LINK_REPOST
            ? CardType.LINK_REPOST
            : "draft",
        stateCard: this.card.state === "drafting" ? "drafting" : "",
        cardLayout: this.layoutType || CardArticleLayoutType.EDITORIAL,
      },
    });
  }

  @computed get isCardInFirstPosition(): boolean {
    return this.id === this.homeStore.CardInFirstPositionId;
  }

  @action.bound onChangeCardLayoutEditorial = flow(
    function* onChangeCardLayoutEditorial(this: CardHomeModel) {
      this.closeContextMenu();
      if (!this.adminOrOwner) return;
      try {
        const res = yield this.deckDetailApi.onChangeCardLayout(
          this.card.id,
          CardArticleLayoutType.EDITORIAL,
        );
        this.card.layoutType = res.layoutType;
      } catch (error) {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: translateAPIError(error),
        });
      }
    },
  );

  @action.bound onChangeCardLayoutPhoto = flow(
    function* onChangeCardLayoutPhoto(this: CardHomeModel) {
      this.closeContextMenu();
      if (!this.adminOrOwner) return;
      try {
        const res = yield this.deckDetailApi.onChangeCardLayout(
          this.card.id,
          CardArticleLayoutType.PHOTO,
        );
        this.card.layoutType = res.layoutType;
      } catch (error) {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: translateAPIError(error),
        });
      }
    },
  );

  @action.bound onChangeCardLayoutText = flow(function* onChangeCardLayoutText(
    this: CardHomeModel,
  ) {
    this.closeContextMenu();
    if (!this.adminOrOwner) return;
    try {
      const res = yield this.deckDetailApi.onChangeCardLayout(
        this.card.id,
        CardArticleLayoutType.TEXT,
      );
      this.card.layoutType = res.layoutType;
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
}
