/* eslint-disable consistent-return */
import { observer } from "mobx-react-lite";
import { NextPage } from "next";
import { Router, useRouter } from "next/router";
import { createRef, useEffect, useMemo, useState } from "react";

import { CardBlank } from "../src/components/CardBlank/CardBlank";
import { CardError } from "../src/components/CardError/CardError";
import { CardItem, CardItemDriver } from "../src/components/CardItem/CardItem";
import { CardJoining } from "../src/components/CardJoining/CardJoining";
import { CardMenu } from "../src/components/CardMenu/CardMenu";
import { CardProfile } from "../src/components/CardProfile/CardProfile";
import { LayoutCardSharing } from "../src/components/CardSharing/LayoutCardSharing";
import { LayoutCardDetailContextMenu } from "../src/components/CardSimpleContext/LayoutCardDetailContextMenu";
import { ContextMenu } from "../src/components/ContextMenu/ContextMenu";
import { ContextMenuRowReport } from "../src/components/ContextMenuRow/ContextMenuRowReport";
import { CookiePolicy } from "../src/components/CookiePolicy/CookiePolicy";
import { DynamicLazyModalLoader } from "../src/components/DynamicModalLoader/DynamicLazyModalLoader";
import { Renderer, RendererArgs } from "../src/components/Grid/Grid";
import { HomeHeaderCard } from "../src/components/HomeHeaderCard/HomeHeaderCard";
import { HomeHeaderCardV092022 } from "../src/components/HomeHeaderCard/HomeHeaderCardV092022";
import { About } from "../src/components/LayoutAbout/About";
import { LayoutErrorDefault } from "../src/components/LayoutError/LayoutErrorDefault";
import { LayoutHome } from "../src/components/LayoutHome/LayoutHome";
import { LayoutMobileMenu } from "../src/components/LayoutMobileMenu/LayoutMobileMenu";
import { LayoutSystemPageTabs } from "../src/components/LayoutSystemPageTabs/LayoutSystemPageTabs";
import { LeaderBoardRange } from "../src/components/LeaderBoardRange/LeaderBoardRange";
import { LeaderBoardRangeSlider } from "../src/components/LeaderBoardRange/LeaderBoardRangeSilder";
import { MobileMenu } from "../src/components/MobileMenu/MobileMenu";
import { PageLayoutBottom } from "../src/components/PageLayoutBottom/PageLayoutBottom";
import { PageLayoutDefault } from "../src/components/PageLayoutDefault/PageLayoutDefault";
import { PageLoader } from "../src/components/PageLoader/PageLoader";
import { SystemPageTabs } from "../src/components/SystemPagesTabs/SystemPagesTabs";
import { TheNavbarTopStateful } from "../src/components/TheNavbarTopStateful/TheNavbarTopStateful";
import { LIST_PREFIX_KEYS } from "../src/shared/constants";
import { deepLinkHost } from "../src/shared/env";
import { isBrowser } from "../src/shared/helpers/isBrowser";
import { safeAsync } from "../src/shared/helpers/safeAsync";
import {
  IClientRenderProps,
  IServerRenderProps,
} from "../src/shared/helpers/serverRender";
import { CardSize } from "../src/shared/models/Card";
import { ContextMenuModel } from "../src/stores/ContextMenuModel";
import { HomeData, HomeStore } from "../src/stores/HomeStore";
import { UonRangeParam } from "../src/stores/LeaderBoardRangeModel";
import { OneFlowScreen } from "../src/stores/ModalOneFlowPresenter/ModalOneFlowPresenter";
import { NamePage } from "../src/stores/OverlaySystemPagePresenter";
import { useRootStore } from "../src/stores/rootStore";

export type IHomeProps =
  | ({
      pageData: HomeData;
    } & IServerRenderProps)
  | IClientRenderProps;

const IndexPage: NextPage<IHomeProps> = observer<IHomeProps>(function IndexPage(
  props,
) {
  const router = useRouter();
  const rootStore = useRootStore();
  const {
    modalStore,
    mobileMenuStore,
    theNavbarStore,
    overlaySystemPagePresenter,
  } = rootStore;
  const { scrollYPosition, updateScrollYPosition } = theNavbarStore;
  const [homeStore] = useState(() => new HomeStore(rootStore));

  const { openModal } = modalStore;
  const cookiePolicyRef = createRef<HTMLDivElement>();
  const isRunEffect =
    !!rootStore.userSessionStore.user &&
    !!router.query.subscriptionId &&
    !!router.query.screen;

  useMemo(async () => {
    if (props.isServerRender) {
      homeStore.hydrate(props.pageData);
    } else {
      isBrowser() && (await homeStore.fetchPageDataServer());
    }
  }, []);

  useEffect(() => {
    (async () => {
      await homeStore.fetchPageDataBrowser();

      if ("scrollRestoration" in history) {
        history.scrollRestoration = "manual";
        window.scrollTo(0, scrollYPosition);
      }
    })();
  }, []);

  useEffect(() => {
    rootStore.setPageStore(homeStore);
    return () => rootStore.removePageStore();
  }, []);

  useEffect(() => {
    (async () => {
      const subscriptionId: string = router.query.subscriptionId as string;
      const screen: string = router.query.screen as string;
      if (
        !rootStore.userSessionStore.user ||
        !subscriptionId ||
        !screen ||
        screen.toLowerCase() !==
          OneFlowScreen.GIFT_SUBSCRIPTION_SUCCESS.toLowerCase()
      ) {
        return;
      }

      const { subscriptionStore, oneFlowStore } = rootStore;
      await subscriptionStore.fetchSubscriptionById(subscriptionId);
      if (!subscriptionStore.subscriptionInfo) {
        return;
      }

      rootStore.donateStore.oneFlowShareId = subscriptionId;

      rootStore.modalStore.openLazyModal({
        name: "oneFlow",
        component: (
          <DynamicLazyModalLoader
            loadComponent={() =>
              import(
                "../src/components/ModalOneFlow/ModalOneFlowGiftSubscriptionSuccess"
              ).then(
                (component) => component.ModalOneFlowGiftSubscriptionSuccess,
              )
            }
            driver={{
              subscriptionId,
              userFullName:
                subscriptionStore.subscriptionInfo.sponsorBy.fullName,
              emailSharingSubject: oneFlowStore.emailSharingSubject,
            }}
          />
        ),
      });
    })();
  }, [isRunEffect]);

  // to fetch user's stars when login on the page
  useEffect(() => {
    (async () => {
      await homeStore.fetchUserCardsMetadata();
    })();
  }, [rootStore.userSessionStore.user?.id, homeStore.cardItems]);

  useEffect(() => {
    if (!router.query.login) return;

    if (
      !rootStore.userSessionStore.didInit ||
      !!rootStore.userSessionStore.user?.id
    ) {
      window.history.replaceState({}, document.title, "/");
      return;
    }

    window.history.replaceState({}, document.title, "/");
    rootStore.theNavbarStore.onOpenLoginModal();
  }, [rootStore.userSessionStore.didInit]);

  useEffect(() => {
    if (!props.isServerRender && "scrollRestoration" in history) {
      history.scrollRestoration = "manual";
      window.scrollTo(0, scrollYPosition);
    }

    const handleRouteChange = () => {
      updateScrollYPosition(window.scrollY);
    };

    Router.events.on("routeChangeStart", handleRouteChange);
    homeStore.updateIsDonated(router.query);
    homeStore.subscribe();

    overlaySystemPagePresenter.updateSystemPageTabs(
      NamePage.Today,
      undefined,
      true,
      false,
    );

    rootStore.overlayAboutStore.updateOverlayAbout();

    return () => {
      Router.events.off("routeChangeStart", handleRouteChange);
      homeStore.unsubscribe();
    };
  }, []);

  const {
    cards,
    cardItems,
    fetchCardError,
    earthTodayProfile,
    fetchEarthTodayProfileError,
    fetchDeckDetailError,
    isLoggedIn,
    fetchPageError,
    leaderboardsRange,
    updateShowOverlay,
  } = homeStore;

  homeStore.setModalFunc(modalStore);

  useMemo(() => {
    if (isLoggedIn) updateShowOverlay(false);
  }, []);

  useMemo(() => {
    if (router.query.codepooldry || router.query.codepoolcollected) {
      openModal("info");
    }
  }, [router.query]);

  const rendeDeckHeader = (): Renderer => {
    if (fetchDeckDetailError) {
      return {
        size: CardSize.SINGLE,
        component: () => <CardError error={fetchDeckDetailError} />,
      };
    }

    return {
      size: CardSize.HEADER,
      component: () => {
        return rootStore.featureFlaggingStore.flags.enableNewHomeHeaderCard ? (
          <HomeHeaderCard
            driver={homeStore.homeHeaderCardDriver}
            flags={rootStore.featureFlaggingStore.flags}
          />
        ) : (
          <HomeHeaderCardV092022
            key={"HomeHeaderCardV092022"}
            driver={homeStore.homeHeaderCardDriver}
            flags={rootStore.featureFlaggingStore.flags}
          />
        );
      },
    };
  };

  const renderCardProfile = (): Renderer => {
    if (fetchEarthTodayProfileError) {
      return {
        size: CardSize.SINGLE,
        component: () => <CardError error={fetchEarthTodayProfileError} />,
      };
    }

    return {
      size: CardSize.SINGLE,
      component: () => {
        if (!earthTodayProfile) {
          return;
        }

        const contextDriver = new ContextMenuModel();

        return (
          <CardProfile
            contextMenu={contextDriver}
            key={earthTodayProfile.id}
            driver={earthTodayProfile}
            openModal={openModal}
            scrollController={mobileMenuStore}
            featureFlaggingStore={rootStore.featureFlaggingStore}
            renderMenuContext={() => (
              <ContextMenu
                driver={contextDriver}
                renderContextMenuItem={() => (
                  <ContextMenuRowReport openModal={openModal} />
                )}
              />
            )}
          />
        );
      },
    };
  };

  const renderCards = (): Renderer[] => {
    if (fetchCardError || cards.length === 0) {
      return [];
    }

    return cardItems.map((card) => ({
      size: card.cardSize,
      component: (rendererArgs: RendererArgs) => {
        return (
          <CardItem
            key={`${card.card.id}feed`}
            driver={card as CardItemDriver}
            layoutDriver={rendererArgs}
            renderMenuContext={() => (
              <LayoutCardDetailContextMenu
                card={card}
                rendererArgs={rendererArgs}
                openModal={openModal}
              />
            )}
            renderSharing={() => <LayoutCardSharing card={card} />}
          />
        );
      },
    }));
  };

  const renderLeaderboardsCustomRange = (): Renderer[] => {
    if (!rootStore.featureFlaggingStore.flags.enableNewHomePage) {
      return [];
    }

    const renderer = Object.values(UonRangeParam).map((range) => {
      const model = leaderboardsRange[range];
      return {
        size: CardSize.SINGLE,
        component: () => (
          <LeaderBoardRange
            key={LIST_PREFIX_KEYS.leaderboardRange + range}
            driver={model}
          />
        ),
      };
    });

    return [
      {
        size: CardSize.HEADER,
        component: () => (
          <LeaderBoardRangeSlider
            items={renderer}
            key={"LeaderBoardRangeSlider"}
          />
        ),
      },
    ];
  };

  const renderSystemPageTabs = () => {
    return (
      <LayoutSystemPageTabs
        driver={overlaySystemPagePresenter}
        renderSystemPageTabs={() => (
          <SystemPageTabs driver={overlaySystemPagePresenter} />
        )}
      />
    );
  };

  return (
    <PageLayoutDefault
      driver={mobileMenuStore}
      renderTop={() => (
        <>
          <CookiePolicy
            cookiePolicyRef={cookiePolicyRef}
            driver={rootStore.userSessionStore}
            flags={rootStore.featureFlaggingStore.flags}
          />
          <TheNavbarTopStateful closeOverlay={homeStore.closeOverlay} />
        </>
      )}
      renderMenu={() => (
        <LayoutMobileMenu
          driver={mobileMenuStore}
          renderMobileMenu={() => <MobileMenu driver={mobileMenuStore} />}
          renderCardMenu={() => (
            <CardMenu
              driver={mobileMenuStore}
              featureFlagStore={rootStore.featureFlaggingStore}
            />
          )}
        />
      )}
      renderCardJoining={() => <CardJoining driver={mobileMenuStore} />}
      renderCardBlank={() => <CardBlank driver={mobileMenuStore} />}
      renderBottom={() => (
        <PageLayoutBottom
          driverMenu={mobileMenuStore}
          driver={rootStore.overlayAboutStore}
        />
      )}
    >
      <About cookiePolicyRef={cookiePolicyRef} driver={rootStore} />
      {homeStore.isPageLoading ? (
        <PageLoader />
      ) : fetchPageError ? (
        <LayoutErrorDefault overlayDriver={rootStore.overlayAboutStore} />
      ) : (
        <LayoutHome
          driverMenu={mobileMenuStore}
          driver={rootStore.overlayAboutStore}
          headerRenderer={[rendeDeckHeader(), renderCardProfile()]}
          customRenderer={[...renderLeaderboardsCustomRange()]}
          renderer={[...renderCards()]}
          renderSystemPageTabs={renderSystemPageTabs}
        />
      )}
    </PageLayoutDefault>
  );
});

IndexPage.displayName = "IndexPage";
IndexPage.getInitialProps = async (ctx): Promise<IHomeProps> => {
  if (ctx.query.crash === "me") {
    throw new Error("Crash Me");
  }

  const {
    query: { oneFlowType, codepooldry, provider },
  } = ctx;

  const shouldRedirectToWelcomePage =
    ctx.req?.headers.host?.startsWith(new URL(deepLinkHost).host) &&
    !oneFlowType &&
    !codepooldry &&
    !provider;

  if (shouldRedirectToWelcomePage && ctx.res) {
    // On the server, we'll use an HTTP response to
    // redirect with the status code of our choice.
    // 307 is for temporary redirects.
    ctx.res.writeHead(307, { Location: "/welcome" });
    ctx.res.end();
  }

  const { rootStore } = ctx as any;

  const pageStore = new HomeStore(rootStore);
  await safeAsync(pageStore.fetchPageDataServer());

  return {
    rootData: rootStore.dehydrate(),
    pageData: pageStore.dehydrate(),
    isServerRender: true,
  };
};

export default IndexPage;
