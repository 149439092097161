import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { etGrayDark } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./CardError.styled";

interface IProps {
  error: string | ReactNode;
}
export const CardError = observer((props: IProps) => {
  const { t } = useTranslation("ToastMessage");

  return (
    <sc.CardErrorContainer>
      <sc.CardErrorContent>
        <IconSvg
          icon="status-warning"
          width="5rem"
          height="5rem"
          padding="0"
          color={etGrayDark}
          pointer={true}
        />
        <sc.CardErrorContentMessage>
          {typeof props.error === "string" ? t(props.error) : props.error}
        </sc.CardErrorContentMessage>
      </sc.CardErrorContent>
    </sc.CardErrorContainer>
  );
});
