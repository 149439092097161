import { observer } from "mobx-react-lite";
import React from "react";

import FlagPoleIcon from "../../assets/img/flagpole-2.png";
import Sky from "../../assets/img/space.png";
import { utmSourceValue } from "../../shared/env";
import { protectPageSectionOneNumberAnimation } from "../../shared/helpers/protectPageSectionOneNumberAnimation";
import { protectflowStarted } from "../../shared/snowplow";
import { useTranslation } from "../../shared/translate/NextI18next";
import { Currency } from "../../stores/DonateStore/DonateStore";
import { DangerouslyHTMLRenderer } from "../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import { InfoBubble } from "../InfoBubble/InfoBubble";
import { LazyImage } from "../LazyImage/LazyImage";
import { TheLoader } from "../TheLoader/TheLoader";
import { M2Icon } from "../UonCountNumberAnimation/M2Icon";
import { UonCountNumberLocation } from "../UonCountNumberAnimation/StylesUonCountNumberAnimation";
import { UonCountNumberAnimation } from "../UonCountNumberAnimation/UonCountNumberAnimation";
import { sc } from "./HomeHeaderCardV092022.styled";

export interface Driver {
  protectedCount: number | null;
  currency: Currency;
  openPreselectionOrOneFlowModal: (dataCy: string) => void;
}

interface IProps {
  driver: Driver;
  flags: {
    counterFlapEffectDuration: number;
    enableOneFlow: boolean;
  };
  noAnimationCounter?: boolean;
}

export const HomeHeaderCardV092022 = observer((props: IProps) => {
  const { t } = useTranslation("EtHeaderCard");
  const { t: tGeneral } = useTranslation("General");
  const {
    flags: { enableOneFlow },
  } = props;

  return (
    <sc.Container
      data-cy="HomeHeaderCardV092022-Container"
      data-track-id="todayDeck_card-homeHeaderCard"
      className="visual-reg"
    >
      <sc.BackgroundContainer>
        <sc.Gradient />
        <sc.TheEarth data-cy="HomeHeaderCardV092022-TheEarth" />
        {enableOneFlow ? (
          <sc.OneFlowSpace data-cy="HomeHeaderCardV092022-Space" />
        ) : (
          <sc.Space data-cy="HomeHeaderCardV092022-Space">
            <LazyImage
              src={Sky}
              width="100%"
              maxWidth="100%"
              height="100%"
              maxHeight="100%"
              alt="sky image"
              position="absolute"
              top="0px"
              left="0px"
            />
          </sc.Space>
        )}
      </sc.BackgroundContainer>
      <sc.Content>
        <sc.TopIcon>
          <sc.FlagPoleIcon data-cy="HomeHeaderCardV092022-Flag">
            <LazyImage
              src={FlagPoleIcon}
              width="100%"
              maxWidth="100%"
              height="100%"
              maxHeight="100%"
              alt="flag pole image"
              position="absolute"
              top="0px"
              left="0px"
            />
          </sc.FlagPoleIcon>
          <sc.SquareMeterIcon data-cy="HomeHeaderCardV092022-SquareMeter" />
        </sc.TopIcon>

        <sc.Title
          isEnableOneFlow={enableOneFlow}
          data-cy="HomeHeaderCardV092022-Title"
        >
          <sc.Text>Subscribe²</sc.Text>
          <sc.AnimationTextWrapper
            noAnimationCounter={!!props.noAnimationCounter}
          >
            <sc.TextAnimate>
              <DangerouslyHTMLRenderer>
                {t("etheadercard.nature")}
              </DangerouslyHTMLRenderer>
            </sc.TextAnimate>
            <sc.TextAnimate>
              <DangerouslyHTMLRenderer>
                {t("etheadercard.bio")}
              </DangerouslyHTMLRenderer>
            </sc.TextAnimate>
            <sc.TextAnimate>
              <DangerouslyHTMLRenderer>
                {t("etheadercard.clean")}
              </DangerouslyHTMLRenderer>
            </sc.TextAnimate>
            <sc.TextAnimate>
              <DangerouslyHTMLRenderer>
                {t("etheadercard.fresh")}
              </DangerouslyHTMLRenderer>
            </sc.TextAnimate>
          </sc.AnimationTextWrapper>
        </sc.Title>

        <sc.CounterWrapper
          isEnableOneFlow={enableOneFlow}
          data-cy="HomeHeaderCardV092022-CounterContainer"
        >
          {props.driver.protectedCount === null ? (
            <TheLoader className="visual-reg-remove" />
          ) : (
            <>
              {protectPageSectionOneNumberAnimation(
                props.driver.protectedCount,
              ).map((count, i) => (
                <UonCountNumberAnimation
                  dataCy={"HomeHeaderCardV092022-CountNumber"}
                  flags={props.flags}
                  noAnimationCounter={props.noAnimationCounter}
                  countNumbers={count.countNumbers}
                  lastNumber={count.lastNumber}
                  key={`${count.lastNumber}${i}`}
                  location={UonCountNumberLocation.HeaderCard}
                />
              ))}
              <M2Icon
                location={UonCountNumberLocation.HeaderCard}
                enableOneFlow={enableOneFlow}
              />
            </>
          )}
        </sc.CounterWrapper>

        <sc.Description
          isEnableOneFlow={enableOneFlow}
          data-cy="HomeHeaderCardV092022-Description"
        >
          {enableOneFlow
            ? props.driver.currency === Currency.USD
              ? t("etheadercard.oneflow.description.usd-title")
              : t("etheadercard.oneflow.description.euro-title")
            : props.driver.currency === Currency.USD
            ? t("etheadercard.description-update.usd-title")
            : t("etheadercard.description-update.euro-title")}
          &nbsp;
          <InfoBubble driver={props.driver} />
        </sc.Description>

        {enableOneFlow && (
          <sc.SubDescription data-cy="HomeHeaderCardV092022-SubDescription">
            {props.driver.currency === Currency.USD
              ? t("etheadercard.oneflow.sub-description.usd-title")
              : t("etheadercard.oneflow.sub-description.euro-title")}
          </sc.SubDescription>
        )}

        {enableOneFlow ? (
          <sc.ButtonsContainer>
            <sc.ProtectLink
              isEnableOneFlow={enableOneFlow}
              data-cy="HomeHeaderCardV092022-ProtectLink"
              data-track-id="todayDeck_card-homeHeaderCard_btn-startProtect"
              onClick={(e) => {
                props.driver.openPreselectionOrOneFlowModal(
                  "HomeHeaderCardV092022-ProtectLink",
                );
                protectflowStarted(e);
              }}
            >
              {tGeneral("general.start-protecting")}
            </sc.ProtectLink>
            <sc.LearnMoreButton
              data-cy="HomeHeaderCardV092022-LearnMore"
              data-track-id="todayDeck_card-homeHeaderCard_btn-learnMore"
              href={`https://gifting.earthtoday.com/?${utmSourceValue}`}
              target="_blank"
            >
              {tGeneral("general.learn-more")}
            </sc.LearnMoreButton>
          </sc.ButtonsContainer>
        ) : (
          <sc.ProtectLink
            isEnableOneFlow={enableOneFlow}
            data-cy="HomeHeaderCardV092022-ProtectLink"
            onClick={(e) => {
              props.driver.openPreselectionOrOneFlowModal(
                "HomeHeaderCardV092022-ProtectLink",
              );
              protectflowStarted(e);
            }}
          >
            {tGeneral("general.start-protecting")}
          </sc.ProtectLink>
        )}
      </sc.Content>
    </sc.Container>
  );
});
