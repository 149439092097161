import { observer } from "mobx-react-lite";
import Slider, { Settings } from "react-slick";

import { largeScreenUpperLimitValue } from "../../shared/breakpoints";
import useWindowSize from "../../shared/helpers/useWindowSize";
import { CardSize } from "../../shared/models/Card";
import { Renderer } from "../Grid/Grid";
import { slider as sc } from "./LeaderBoardRange.styled";

interface IProps {
  items: Renderer[];
}

export const LeaderBoardRangeSlider = observer((props: IProps) => {
  const { width } = useWindowSize();

  if (width > largeScreenUpperLimitValue) {
    return (
      <>
        {props.items.map((item) =>
          item.component({ cardSize: CardSize.SINGLE }),
        )}
      </>
    );
  }

  const SamplePrevArrow = (props) => {
    return (
      <sc.Left onClick={props.onClick} data-cy="SamplePrevArrow">
        <img src={require("../../assets/img/arrow-left.png")} alt="" />
      </sc.Left>
    );
  };

  const SampleNextArrow = (props) => {
    return (
      <sc.Right onClick={props.onClick} data-cy="SampleNextArrow">
        <img src={require("../../assets/img/arrow-right.png")} alt="" />
      </sc.Right>
    );
  };

  const settings: Settings = {
    swipeToSlide: true,
    variableWidth: true,
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    initialSlide: 0,
    appendDots: (dots) => {
      return <sc.NavBottom>{dots}</sc.NavBottom>;
    },
  };

  return (
    <sc.Container>
      <sc.Slider>
        <Slider {...settings}>
          {props.items.map((item) =>
            item.component({ cardSize: CardSize.SINGLE }),
          )}
        </Slider>
      </sc.Slider>
    </sc.Container>
  );
});
