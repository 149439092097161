import styled from "styled-components";

import {
  largeScreenUpperLimit,
  mediumScreenUpperLimit,
} from "../../shared/breakpoints";
import { etBlack, etBrightLightBlue, etWhite } from "../../shared/colors";

namespace sc {
  export const Container = styled.div`
    position: relative;
    grid-column: 3 span;
    border-radius: 8px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    width: 100%;

    @media (max-width: ${largeScreenUpperLimit}) {
      grid-column: 2 span;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      grid-column: 1 span;
    }
  `;

  export const BackgroundOverlay = styled.div`
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${etBlack};
    opacity: 0.3;
    z-index: 1;
  `;

  export const BackgroundContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 45px;
    padding-left: 12px;
    padding-right: 12px;

    background-repeat: no-repeat;
    background-position: start;
    background-size: cover;
    background-color: ${etBlack};
    background-image: url(${require("../../assets/img/HomeHeaderCard.png")});
    border-radius: 8px;
  `;

  export const SquareMeterLogo = styled.img`
    min-height: 65px;
    max-height: 65px;
    min-width: 65px;
    max-width: 65px;

    z-index: 1;

    @media (max-width: ${mediumScreenUpperLimit}) {
      min-height: 71px;
      max-height: 71px;
      min-width: 71px;
      max-width: 71px;
    }
  `;

  export const LastProtector = styled.div`
    margin-top: 10px;
    width: 100%;
    height: 16px;
    color: ${etWhite};
    font-size: 16px;
    font-family: Roboto Condensed;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    line-height: 16px;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  export const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    z-index: 1;
  `;

  export const Title = styled.h2`
    color: ${etWhite};
    font-size: 35px;
    font-family: Roboto;
    font-weight: 300;
    text-align: center;
    letter-spacing: -0.5px;
    line-height: 38px;
    margin-top: 36px;

    @media (max-width: ${largeScreenUpperLimit}) {
      font-size: 30px;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 27px;
    }
  `;

  export const SubTitle = styled.h3`
    text-align: center;
    color: ${etWhite};
    font-family: Roboto;
    font-size: 35px;
    font-weight: bold;
    line-height: 35px;

    @media (max-width: ${largeScreenUpperLimit}) {
      font-size: 30px;
      line-height: 30px;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 27px;
      line-height: 27px;
    }
  `;

  export const Description = styled.p`
    height: 28px;
    color: ${etWhite};
    font-size: 24px;
    font-family: Roboto Condensed;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 28px;
    text-align: center;
    margin-top: 27.5px;

    @media (max-width: ${largeScreenUpperLimit}) {
      font-size: 20px;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 16px;
    }
  `;

  export const CounterWrapper = styled.div`
    margin-top: 13px;
    margin-left: 3px;
    display: flex;
    justify-content: center;

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-top: 17px;
    }
  `;
  export const CounterMeterLogo = styled.img`
    min-height: 57px;
    max-height: 57px;
    min-width: 57px;
    max-width: 57px;
    margin-left: -4px;

    z-index: 1;

    @media (max-width: ${mediumScreenUpperLimit}) {
      min-height: 26.74px;
      max-height: 26.74px;
      min-width: 26.74px;
      max-width: 26.74px;
      margin-left: -2px;
    }
  `;

  export const ButtonActionContainer = styled.div`
    display: flex;
    justify-content: center;
  `;

  export const JoinUsButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
    font-size: 16px;
    font-family: Roboto Condensed;
    text-transform: uppercase;
    color: ${etWhite};
    background-color: ${etBrightLightBlue};
    border: none;
    border-radius: 24px;

    width: 180px;
    height: 40px;
    cursor: pointer;

    &:hover {
      color: ${etWhite};
      text-decoration: none;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;
}

export { sc };
