import { observer } from "mobx-react-lite";

import { protectPageSectionOneNumberAnimation } from "../../shared/helpers/protectPageSectionOneNumberAnimation";
import { useTranslation } from "../../shared/translate/NextI18next";
import { Currency } from "../../stores/DonateStore/DonateStore";
import { ModalType } from "../../stores/ModalStore";
import { HomeHeaderCardCountAnimation } from "../HomeHeaderCardCounter/HomeHeaderCardCountAnimation";
import { InfoBubble } from "../InfoBubble/InfoBubble";
import { TheLoader } from "../TheLoader/TheLoader";
import { sc } from "./HomeHeaderCard.styled";

export interface HomeHeaderCardDriver {
  lastProtectorName: string;
  protectedCount: number | null;
  currency: Currency;
  openModal(modalType: ModalType): void;
  openPreselectionOrOneFlowModal(dataCy: string): void;
}

interface IProps {
  driver: HomeHeaderCardDriver;
  flags: {
    counterFlapEffectDuration: number;
    enableNewHomePage: boolean;
  };
  noAnimationCounter?: boolean;
}

export const HomeHeaderCard = observer(function HomeHeaderCard(props: IProps) {
  const { t } = useTranslation("EtHeaderCard");

  return (
    <sc.Container className="visual-reg" data-cy="HomeHeaderCard-Container">
      <sc.BackgroundOverlay />
      <sc.BackgroundContent>
        <sc.SquareMeterLogo
          data-cy="HomeHeaderCard-SquareMeterLogo"
          src={require("../../assets/img/HomeHeaderCardM2.png")}
          alt="Home Header Card m²"
        />
        <sc.LastProtector
          data-cy="HomeHeaderCard-LastProtector"
          title={props.driver.lastProtectorName}
        >
          {props.driver.lastProtectorName}
        </sc.LastProtector>
        <sc.Body>
          <sc.Title data-cy="HomeHeaderCard-Title">
            {t("etheadercard.title")}
          </sc.Title>

          <sc.SubTitle data-cy="HomeHeaderCard-SubTitle">
            {t("etheadercard.sub-title")}
          </sc.SubTitle>

          <sc.CounterWrapper>
            {props.driver.protectedCount === null ? (
              <TheLoader className="visual-reg-remove" />
            ) : (
              <>
                {protectPageSectionOneNumberAnimation(
                  props.driver.protectedCount,
                ).map((count, i) => (
                  <HomeHeaderCardCountAnimation
                    dataCy={"HomeHeaderCardCountAnimation-CountNumber"}
                    flags={props.flags}
                    noAnimationCounter={props.noAnimationCounter}
                    countNumbers={count.countNumbers}
                    lastNumber={count.lastNumber}
                    key={`${count.lastNumber}${i}`}
                  />
                ))}
                <sc.CounterMeterLogo
                  data-cy="HomeHeaderCardCountAnimation-SquareMeterLogo"
                  src={require("../../assets/img/HomeHeaderCardM2.png")}
                  alt="Home Header Card Counter m²"
                />
              </>
            )}
          </sc.CounterWrapper>

          <sc.Description data-cy="HomeHeaderCard-Description">
            {props.driver.currency === Currency.USD
              ? t("etheadercard.description.usd-title")
              : t("etheadercard.description.euro-title")}
            &nbsp;
            <InfoBubble driver={props.driver} />
          </sc.Description>

          <sc.ButtonActionContainer>
            <sc.JoinUsButton
              onClick={() =>
                props.driver.openPreselectionOrOneFlowModal(
                  "HomeHeaderCard-JoinUsButton",
                )
              }
              data-cy="HomeHeaderCard-JoinUsButton"
            >
              {t("etheadercard.cta-button-text")}
            </sc.JoinUsButton>
          </sc.ButtonActionContainer>
        </sc.Body>
      </sc.BackgroundContent>
    </sc.Container>
  );
});
