import styled from "styled-components";

import { etWhite } from "../../shared/colors";

namespace sc {
  export const CardErrorContainer = styled.div`
    position: relative;
    width: 100%;
    height: 25rem;
    margin: 1rem 0;
  `;
  export const CardErrorContent = styled.div`
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 1rem 1.5rem;
    background-color: ${etWhite};
  `;
  export const CardErrorContentMessage = styled.span`
    margin-left: 1.5rem;
  `;
}
export default sc;
