import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { mediumScreenLowerLimitValue } from "../../shared/breakpoints";
import useWindowSize from "../../shared/helpers/useWindowSize";
import { Grid, Renderer } from "../Grid/Grid";
import sc from "./LayoutHome.styled";

interface Driver {
  readonly isOverlayActive: boolean;
}
interface IProps {
  headerRenderer: Renderer[];
  customRenderer: Renderer[];
  renderer: Renderer[];

  renderSystemPageTabs(): ReactNode;
  driver: Driver;
  driverMenu: { isCardJoiningOpen: boolean; isMenuAppOpen: boolean };
}

export const LayoutHome = observer(function (props: IProps) {
  const windowSize = useWindowSize();

  return (
    <sc.Container
      isCardJoiningOpen={props.driverMenu.isCardJoiningOpen}
      isMenuAppOpen={props.driverMenu.isMenuAppOpen}
      isOverlayActive={props.driver.isOverlayActive}
    >
      <sc.GridWrapper>
        {props.renderSystemPageTabs()}
        {props.headerRenderer.length ? (
          <Grid renderer={props.headerRenderer} padding={"24px 0 0 0"} />
        ) : undefined}
        {props.customRenderer.length ? (
          <Grid
            renderer={props.customRenderer}
            padding={
              windowSize.width <= mediumScreenLowerLimitValue
                ? "24px 0 48px 0"
                : "24px 0 0 0"
            }
            minHeight="496px"
          />
        ) : undefined}
        {props.renderer.length ? <Grid renderer={props.renderer} /> : undefined}
      </sc.GridWrapper>
    </sc.Container>
  );
});
