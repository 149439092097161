import { action, computed, makeObservable, observable } from "mobx";

import { HomeHeaderCardDriver } from "../components/HomeHeaderCard/HomeHeaderCard";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { IConsumer } from "../shared/models/Consumer";
import { Currency, DonateStore } from "./DonateStore/DonateStore";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import {
  OneFlowScreen,
  OneFlowType,
} from "./ModalOneFlowPresenter/ModalOneFlowPresenter";
import { IModalStore, ModalType } from "./ModalStore";

export class HomeHeaderCardStore implements HomeHeaderCardDriver {
  constructor(
    private donateStore: DonateStore,
    private modalStore: IModalStore,
    private featureFlaggingStore: FeatureFlaggingData,
    private userSessionStore: IUserSessionStore,
  ) {
    makeObservable(this);
  }
  @observable lastProtector: IConsumer | null = null;

  @computed get lastProtectorName(): string {
    return this.lastProtector?.name || "";
  }

  @observable protectedCount: number | null = null;
  @computed get currency(): Currency {
    return this.donateStore.currency;
  }

  openModal(modalType: ModalType): void {
    this.modalStore.openModal(modalType);
  }

  @action.bound openPreselectionOrOneFlowModal(dataCy: string): void {
    this.donateStore.updateTriggerPointDataCy(dataCy);
    if (
      !this.featureFlaggingStore.flags.enableOneFlow ||
      this.userSessionStore.user?.isGroupUnpublished
    ) {
      this.donateStore.openPreselection();
      return;
    }

    this.modalStore.openModal({
      name: "oneFlow",
      initialState: {
        currentScreen: OneFlowScreen.START,
        oneFlowType: OneFlowType.SUBSCRIBE,
      },
    });
  }
}
