import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
export interface LayoutSystemPageTabsDriver {
  shouldRenderNewMobileMenu: boolean;
  isSystemPageTabsActive: boolean;
}
export interface LayoutSystemPageTabsProps {
  driver: LayoutSystemPageTabsDriver;
  renderSystemPageTabs: () => ReactNode;
}

export const LayoutSystemPageTabs = observer(function LayoutSystemPageTabs(
  props: LayoutSystemPageTabsProps,
) {
  const { shouldRenderNewMobileMenu, isSystemPageTabsActive } = props.driver;

  return (
    <>
      {!shouldRenderNewMobileMenu && isSystemPageTabsActive
        ? props.renderSystemPageTabs()
        : null}
    </>
  );
});
