import styled, { keyframes } from "styled-components";

import {
  largeScreenLowerLimit,
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  xLargeScreenLowerLimit,
} from "../../shared/breakpoints";
import { etBrightLightBlue, etWhite } from "../../shared/colors";
import scUonCount from "../UonCountNumberAnimation/UonCountNumberAnimation.styled";

namespace sc {
  const wordChange = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    2% {
      opacity: 1;
      transform: translateY(0px);
    }
    18% {
      opacity: 1;
      transform: translateY(0px);
    }
    30% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  `;

  export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    border-radius: 8px;
    overflow: hidden;

    height: 496px;

    grid-column: 3 span;
    border-radius: 8px;

    @media (max-width: ${largeScreenUpperLimit}) {
      grid-column: 2 span;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      grid-column: 1 span;
    }
  `;

  export const BackgroundContainer = styled.div`
    height: 496px;
    width: 100%;
    position: absolute;
  `;

  export const TheEarth = styled.div`
    background: url(${require("../../assets/img/the-earth.png")});
    background-size: 599px;
    background-position: center 70px;
    background-repeat: no-repeat;

    height: 496px;
    width: 100%;

    position: absolute;
    z-index: 2;

    @media (max-width: ${mediumScreenUpperLimit}) {
      background-image: url(${require("../../assets/img/the-earth-mobile.png")});
      background-position: center center;
      background-size: contain;

      max-width: 294px;
      max-height: 294px;
      left: 50%;
      transform: translateX(-50%);
      top: 95px;
    }
  `;

  export const Space = styled.div`
    background-size: cover;
    height: 496px;
    width: 100%;

    position: absolute;
    z-index: 1;
  `;

  export const OneFlowSpace = styled.div`
    background: url(${require("../../assets/img/space.png")});
    background-size: cover;
    height: 496px;
    width: 100%;

    position: absolute;
    z-index: 1;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      background-image: url(${require("../../assets/img/space-2-column.png")});
    }

    @media (max-width: ${largeScreenLowerLimit}) {
      background-image: url(${require("../../assets/img/space-1-column.png")});
    }
  `;

  export const Gradient = styled.div`
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 100%
    );

    height: 496px;
    width: 100%;

    position: absolute;
    z-index: 3;

    @media (max-width: ${mediumScreenUpperLimit}) {
      background: linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 9%,
        rgb(0, 0, 0) 96%
      );
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
      opacity: 0.9;
    }
  `;

  export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column;

    position: relative;
    z-index: 10;

    height: 432px;
    width: 100%;

    @media (max-width: ${mediumScreenUpperLimit}) {
      max-height: 400px;
    }
  `;

  export const TopIcon = styled.div``;

  export const FlagPoleIcon = styled.div`
    height: 98px;
    width: 74px;

    position: absolute;
    left: calc(50% + 26.5px);
    top: -2px;
    transform: translateX(-50%);

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: 69px;
      max-height: 93px;

      top: -2px;
      left: calc(50% + 25px);
    }
  `;

  export const SquareMeterIcon = styled.div`
    background: url(${require("../../assets/img/m2-white.png")}) center center
      no-repeat;
    background-size: contain;

    width: 57px;
    height: 57px;

    margin-top: 96px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: 42px;
      height: 42px;

      margin-top: 78px;
    }
  `;

  export const Text = styled.p`
    margin: 0;
    color: ${etWhite};
    font-family: Roboto;
    font-size: 32px;
    font-weight: 300;

    letter-spacing: 0px;
    line-height: 40px;
    text-align: left;

    span > span {
      font-weight: 500;
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 24px;
      line-height: 26px;
    }
  `;

  export const TextAnimate = styled(Text)`
    position: absolute;
    top: 0;
    left: 8px;
    overflow: hidden;
    animation: ${wordChange} 6s linear infinite;
    opacity: 0;

    :nth-child(1) {
      animation-delay: 0;
    }
    :nth-child(2) {
      animation-delay: 1.5s;
    }
    :nth-child(3) {
      animation-delay: 3s;
    }
    :nth-child(4) {
      animation-delay: 4.5s;
    }
  `;

  export const AnimationTextWrapper = styled.div<{
    noAnimationCounter: boolean;
  }>`
    width: 178px;
    position: relative;

    ${TextAnimate} {
      :nth-child(1) {
        ${(props) =>
          props.noAnimationCounter &&
          `
          opacity: 1;
          transform: translateY(0px);
        `}
      }

      ${(props) =>
        props.noAnimationCounter &&
        `
        animation: none;
        
    `}
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: 135px;
    }
  `;

  export const Title = styled.div<{ isEnableOneFlow: boolean }>`
    display: flex;
    justify-content: center;

    max-height: 40px;
    min-height: 40px;
    overflow: hidden;

    margin: ${(props) =>
      props.isEnableOneFlow ? "15px 0 9px 0" : "16px 0 12px 0"};

    @media (max-width: ${mediumScreenUpperLimit}) {
      max-height: 26px;
      min-height: 26px;
      margin-top: ${(props) => (props.isEnableOneFlow ? "17px" : "12px")};
      margin-bottom: 7px;
    }
  `;

  export const Description = styled.span<{ isEnableOneFlow: boolean }>`
    display: inline-block;

    color: ${etWhite};
    font-family: Roboto Condensed;
    text-align: center;
    text-transform: uppercase;

    font-weight: normal;
    font-size: 22px;
    letter-spacing: 0px;
    line-height: 28px;
    max-width: ${(props) => (props.isEnableOneFlow ? "560px" : "430px")};
    margin-top: ${(props) => (props.isEnableOneFlow ? "33px" : "26px")};

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 16px;
      line-height: 20px;
      max-width: ${(props) => (props.isEnableOneFlow ? "214px" : "265px")};
      margin-top: ${(props) => (props.isEnableOneFlow ? "23px" : "28px")};
    }
  `;

  export const SubDescription = styled.span`
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: ${etWhite};
    margin-top: 21px;
    margin-bottom: 11px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-top: 22px;
      margin-bottom: 7px;
    }
  `;

  export const CounterWrapper = styled.div<{ isEnableOneFlow: boolean }>`
    width: fit-content;
    min-width: 364px;
    min-height: 50px;
    margin-top: 13px;
    margin-left: 3px;
    display: flex;
    justify-content: flex-start;
    > div {
      min-height: 57px;
      max-height: 57px;
      min-width: 57px;
      max-width: 57px;
    }

    ${scUonCount.Chain} {
      left: 43px;
      top: 21px;

      &:before {
        right: 37px;
      }
    }

    .flap {
      width: 50px !important;
      height: 50px !important;

      [data-kind="digit"] {
        font-size: 44px !important;
        line-height: 52px !important;
      }

      > div {
        margin-top: -2px;
      }

      > div:nth-child(2),
      > div:nth-child(4) {
        margin-top: 0px !important;
        margin-bottom: 2px !important;
      }
    }

    @media (max-width: ${largeScreenUpperLimit}) {
      margin-top: ${(props) => (props.isEnableOneFlow ? "5px" : "17px")};
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      min-width: 256px;
      min-height: 32px;
      margin-left: 0;
      > div {
        min-height: 32px;
        max-height: 32px;
        min-width: 32px;
        max-width: 32px;
      }

      justify-content: center;
      width: 100%;

      ${scUonCount.Chain} {
        left: 22.5px;
        top: 12px;

        &:before {
          right: 18px;
        }
      }

      .flap {
        width: 24px !important;
        height: 24px !important;
        margin-top: 2px;

        [data-kind="digit"] {
          font-size: 21px !important;
          line-height: 25px !important;
        }

        > div {
          margin-top: -2px;
        }

        > div:nth-child(2),
        > div:nth-child(4) {
          margin-top: 1px !important;
          margin-bottom: 2px !important;
        }
      }
    }
  `;

  export const ProtectLink = styled.button<{ isEnableOneFlow: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
    font-size: 16px;
    font-family: Roboto Condensed;
    text-transform: uppercase;
    color: ${etWhite};
    background-color: ${etBrightLightBlue};
    border: none;
    border-radius: 24px;

    width: 186px;
    height: 40px;
    margin-top: auto;
    cursor: pointer;

    &:hover {
      color: ${etWhite};
      text-decoration: none;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      margin-top: ${(props) => (props.isEnableOneFlow ? "auto" : "24px")};
    }
  `;

  export const ButtonsContainer = styled.div`
    display: flex;
    gap: 12px;

    @media (max-width: ${largeScreenLowerLimit}) {
      flex-direction: column;
      gap: 14px;
    }
  `;

  export const LearnMoreButton = styled.a`
    border: 1px solid ${etWhite};
    color: ${etWhite};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    width: 186px;
    height: 40px;
    font-family: Roboto Condensed;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    user-select: none;

    &:hover {
      color: ${etWhite};
      text-decoration: none;
      opacity: 0.9;
    }
  `;
}

export { sc };
